import { Link } from "react-router-dom";

import CreateAccountForm from "main/create-account/CreateAccountForm";
import TitleLayout from "main/layouts/TitleLayout";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import { AppRoutes } from "main/app/App";
import { FC } from "react";

const CreateAccountPage: FC = () => {
    usePageTitle("Create your Organization");

    return (
        <TitleLayout>
            <div className="form-title">Create Account</div>
            <CreateAccountForm />
            <div className="mvm h6">
                Already have an account?&nbsp;
                <Link
                    to={AppRoutes.lookupPath}
                    onClick={() => Analytics.event(Events.sign_in)}
                >
                    Sign In
                </Link>
            </div>
        </TitleLayout>
    );
};

export default CreateAccountPage;
