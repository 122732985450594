import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "main/app/App";
import { CreateOrgRequest } from "main/create-account/types";
import { apiClient } from "main/utils/ApiClient";
import { ErrorType, SessionState } from "main/app/types";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import Analytics, { Events } from "main/utils/Analytics";
import { errorUrl } from "main/error/ErrorPage";

type OrganizationHook = {
    loading: boolean;
    createOrganization: (request: CreateOrgRequest) => void;
};

export const useOrganization = (): OrganizationHook => {
    const { loading, execute: createOrganizationCall } = useLoadingCall(
        apiClient.createOrganization
    );
    const navigate = useNavigate();

    const createOrganization = (request: CreateOrgRequest) => {
        createOrganizationCall(request)
            .then((session: SessionState) => {
                navigate(AppRoutes.welcomePath, { state: { session } });
            })
            .catch((e: AxiosError) => {
                Analytics.event(Events.create_account_error);
                if (e.message.includes("Failed to create Organization")) {
                    navigate(errorUrl(ErrorType.ORG_CREATION_ERROR));
                } else {
                    handleResponse(e, "Unexpected Error");
                }
            });
    };
    return { loading, createOrganization };
};

export default useOrganization;
