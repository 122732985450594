import Footer from "main/Footer";
import {
    unenrollDevicesHook,
    useDashboard,
    useMemberUpload,
    useUnenrollDevices,
} from "main/dashboard/hooks";
import { useActionMenu } from "main/dashboard/useActionMenu";
import useDidMountEffect from "main/utils/useDidMountEffect";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddMembersHint from "components/add-members-hint/AddMembersHint";
import { Button } from "components/button/Button";
import { useConfirmationModal } from "components/modal/Modal";
import {
    DEFAULT_PAGE,
    EnrolledFilter,
    Filters,
    PaginationQueryParams,
} from "main/app/types";
import Table from "main/dashboard/components/Table";
import { DeviceRow } from "main/dashboard/types";
import { useFilter } from "main/dashboard/useFilter";
import { useUnenrollModal } from "main/dashboard/useUnenrollModal";
import Analytics, { Events } from "main/utils/Analytics";
import TableFooter from "./components/TableFooter";

const Dashboard: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState<DeviceRow[]>([]);
    const [rowSelections, setRowSelections] = useState<DeviceRow[]>([]);
    const tableRef = useRef<HTMLDivElement>(null);

    const {
        loading: dashboardLoading,
        currentPage,
        pageSize,
        paginatedDevices,
        totalPages,
        fetchDashboardRows,
        setCurrentPage,
        setPageSize,
        clearPaginatedDevices,
    } = useDashboard();
    const { goToMemberUpload } = useMemberUpload();
    const { filterMenu, filters } = useFilter();

    // Add page, pageSize and filters to url query params on initial render
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        query.set(PaginationQueryParams.PAGE, currentPage.toString());
        query.set(PaginationQueryParams.PAGE_SIZE, pageSize.toString());
        if (filters.enrolled === EnrolledFilter.NONE) {
            query.delete(Filters.ENROLLED);
        } else {
            query.set(Filters.ENROLLED, filters.enrolled as string);
        }

        if (filters.severity) {
            query.delete(Filters.SEVERITY);
            Object.entries(filters.severity).forEach(([key, value]) => {
                if (value) {
                    if (!query.getAll(Filters.SEVERITY).includes(key)) {
                        query.append(Filters.SEVERITY, key);
                    }
                } else if (query.getAll(Filters.SEVERITY).includes(key)) {
                    query.delete(Filters.SEVERITY, key);
                }
            });
        }

        navigate({
            search: query.toString(),
        });
    }, []);

    useEffect(() => {
        if (!paginatedDevices[currentPage]) {
            fetchDashboardRows(currentPage, pageSize, filters);
        } else {
            const pageRows = paginatedDevices[currentPage] || [];
            setFilteredData(pageRows);
        }
    }, [currentPage]);

    useDidMountEffect(() => {
        clearPaginatedDevices();
        fetchDashboardRows(DEFAULT_PAGE, pageSize, filters);
        setCurrentPage(DEFAULT_PAGE);
    }, [pageSize]);

    useEffect(() => {
        const pageRows = paginatedDevices[currentPage] || [];
        setFilteredData(pageRows);
    }, [paginatedDevices]);

    useDidMountEffect(() => {
        clearPaginatedDevices();
        fetchDashboardRows(DEFAULT_PAGE, pageSize, filters);
        setCurrentPage(DEFAULT_PAGE);
    }, [filters]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollTop = 0;
        }
    }, [filteredData]);

    const { unenrollDevices }: unenrollDevicesHook = useUnenrollDevices();
    const { unenrollModalState } = useUnenrollModal();
    const { showModal, modal } = useConfirmationModal({
        ...unenrollModalState(rowSelections),
    });
    const { actionsMenu } = useActionMenu(showModal);

    const handleAddMemberClick = () => {
        Analytics.event(Events.add_members);
        goToMemberUpload();
    };

    return (
        <div className="flex-col flex-1 h-vh-95">
            <div className="flex-col flex-1">
                {modal(unenrollDevices(rowSelections))}
                <div
                    data-testid="dashboard"
                    className="dashboard flex flex-row phm"
                >
                    <div className="dashboard-title basis-2/12">Dashboard</div>
                    <div className="dashboard-options basis-5/12 self-center">
                        {filterMenu}
                    </div>
                    <div className="dashboard-options basis-5/12 self-center">
                        <div className="flex flex-row justify-end">
                            {actionsMenu(rowSelections)}
                            <Button
                                data-testid="add-members-btn"
                                className="h-8 py-0 px-2 self-center"
                                onKeyDown={handleAddMemberClick}
                                onClick={handleAddMemberClick}
                                label="+ Add Devices"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="dashboard-table mt-4 mb-1 rounded-md overflow-y-scroll h-vh-80"
                    ref={tableRef}
                >
                    <>
                        <div className="min-h-[92%]">
                            <Table
                                data={filteredData}
                                loading={dashboardLoading}
                                setSelections={setRowSelections}
                            />
                        </div>
                        <TableFooter
                            totalPages={totalPages}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            paginate={setCurrentPage}
                            setPageSize={setPageSize}
                        />
                    </>
                </div>
                <AddMembersHint
                    hasMembers={!!filteredData.length}
                    addTeamMembersOnClick={handleAddMemberClick}
                />
            </div>
            <Footer />
        </div>
    );
};

export default Dashboard;
