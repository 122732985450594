import { loadStripe } from "@stripe/stripe-js";
import OpenRoutes from "main/app/OpenRoutes";
import ProtectedRoutes from "main/app/ProtectedRoutes";
import { ApplicationState } from "main/app/types";
import ErrorPage from "main/error/ErrorPage";
import LogoutPage from "main/login/LogoutPage";
import SessionHydrator from "main/login/SessionHydrator";
import WelcomePage from "main/pages/WelcomePage";

import SettingsPage from "main/settings/SettingsPage";
import useAnalytics from "main/utils/RouteAnalytics";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { FC } from "react";
import env from "env";

export const AppRoutes = {
    twitterPath: "https://twitter.com/IsMyPhoneHacked/",
    termsPath: "https://www.iverify.io/terms-and-conditions",
    privacyPath: "https://www.iverify.io/privacy-policy",
    supportPath: "https://www.iverify.io/frequently-asked-questions",
    mdmSetupDocsPath: "https://docs.iverify.io/mdm",
    biometricsPath:
        "https://docs.iverify.io/android-biometrics-with-work-and-personal-profiles",
    webhooksDocsPath: "https://docs.iverify.io/iverify-webhooks",
    apiDocsPath: "https://docs.iverify.io/iverify-api",
    conditionalAccessDocsPath:
        "https://docs.iverify.io/conditional-access-management",
    supportEmailPath: "mailto:support@iverify.io",
    salesEmailPath: "mailto:sales@iverify.io",
    dashboardPath: "/dashboard",
    errorPath: "/error",
    hydrateSessionPath: "/sso-login",
    loginPath: "/login",
    logoutPath: "/logout",
    lookupPath: "/look-up",
    memberConfirmPath: "/member-confirm",
    memberGroupsPath: "/member-sso-groups",
    memberManagementPath: "/member-management",
    memberManualUploadPath: "/member-manual-upload",
    memberUploadPath: "/member-upload",
    resetPasswordPath: "/password-reset",
    addAdminPath: "/add-admin",
    resendAdminInvitePath: "/resend-admin-invite",
    settingsPath: "/settings",
    signupPath: "/sign-up",
    ssoSetupPath: "/sso-setup",
    ssoSelectionPath: "/sso-selection",
    updatePasswordPath: "/update-password/:token",
    welcomePath: "/welcome",
    alertsConsolePath: "/alerts-console",
};

export const loadedStripePromise = loadStripe(env.STRIPE_PUBLIC_KEY)
    .then((response) => response)
    .catch(() => null);

const App: FC = () => {
    useAnalytics();

    const hasSession = useSelector(
        (state: ApplicationState) => state.session.user.name !== ""
    );
    const routes = hasSession ? (
        <Route path="*" element={<ProtectedRoutes />} />
    ) : (
        <Route path="*" element={<OpenRoutes />} />
    );

    return (
        <Routes>
            <Route path={AppRoutes.logoutPath} element={<LogoutPage />} />
            <Route path={AppRoutes.errorPath} element={<ErrorPage />} />
            <Route path={AppRoutes.welcomePath} element={<WelcomePage />} />
            <Route path={AppRoutes.settingsPath} element={<SettingsPage />} />
            <Route
                path={AppRoutes.hydrateSessionPath}
                element={<SessionHydrator />}
            />
            {routes}
        </Routes>
    );
};

export default App;
