import { FC, FormEvent, useEffect, useState } from "react";
import Analytics, { Events } from "main/utils/Analytics";
import { Button } from "components/button/Button";
import { AppRoutes } from "main/app/App";
import { LabeledInput } from "components/input/Input";
import {
    isPasswordValid,
    passwordHint,
    showErrorSuccessIcon,
} from "main/utils/validate";
import useOrganization from "main/create-account/hooks";
import useEmailInput from "components/EmailInput/EmailInput";
import { useForm } from "components/form/useForm";
import { Link } from "components/Link";

const CreateAccountForm: FC = () => {
    const { inputs, onChange, filled, valid, setValid } = useForm({
        name: "",
        company: "",
        email: "",
        password: "",
        passwordMatch: "",
        terms: false,
    });

    const { loading, createOrganization } = useOrganization();
    const [isPasswordActive, setIsPasswordActive] = useState<boolean>(false);
    const [isPasswordMatchActive, setIsPasswordMatchActive] =
        useState<boolean>(false);
    const [passwordErrors, setPasswordErrors] = useState<React.ReactNode>(null);
    const [hasPasswordErrors, setHasPasswordErrors] = useState<boolean>(false);
    const [hasPasswordMatchError, setHasPasswordMatchError] =
        useState<boolean>(false);
    const { emailInput, error: emailError } = useEmailInput();

    useEffect(() => {
        const noErrors =
            !emailError && !hasPasswordErrors && !hasPasswordMatchError;
        setValid(filled && noErrors);
    }, [filled, emailError, passwordErrors, hasPasswordMatchError]);

    const showPasswordHint = () => {
        setIsPasswordActive(true);
        if (!hasPasswordErrors && !inputs.password) {
            setPasswordErrors(passwordHint);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        Analytics.event(Events.create_account);

        const request = {
            company: inputs.company as string,
            name: inputs.name as string,
            email: inputs.email as string,
            password: inputs.password as string,
        };

        createOrganization(request);
    };

    const validatePassword = (e: FormEvent) => {
        const password = (e.target as HTMLInputElement).value;
        const { hasError, errors } = isPasswordValid(password, false);
        setPasswordErrors(errors);
        setHasPasswordErrors(hasError);
    };

    const checkPasswordErrors = (e: FormEvent) => {
        if (inputs.password) {
            setIsPasswordActive(false);
            const password = (e.target as HTMLInputElement).value;
            const { hasError, errors } = isPasswordValid(password);

            if (password === "" || !hasPasswordErrors) {
                setPasswordErrors(null);
            }
            setHasPasswordErrors(hasError);
            setPasswordErrors(errors);
        }
    };

    const validatePasswordMatch = (e: FormEvent) => {
        setIsPasswordMatchActive(false);
        const passwordMatch = (e.target as HTMLInputElement).value;
        if (passwordMatch === "" || passwordMatch === inputs.password) {
            setHasPasswordMatchError(false);
        } else {
            setHasPasswordMatchError(true);
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                onChange={onChange}
                className="width-small"
            >
                <LabeledInput
                    name="name"
                    label="Full Name"
                    placeholder="First Last"
                    maxLength={256}
                />
                <LabeledInput
                    name="company"
                    label="Organization Name"
                    placeholder="Acme Corporation"
                    maxLength={256}
                />
                {emailInput}
                <LabeledInput
                    name="password"
                    label="Password"
                    placeholder="password 8+ characters"
                    type="password"
                    autoComplete="password"
                    onFocus={showPasswordHint}
                    onInput={validatePassword}
                    onBlur={checkPasswordErrors}
                    error={passwordErrors}
                    icon={showErrorSuccessIcon(
                        inputs.password as string,
                        isPasswordActive,
                        hasPasswordErrors
                    )}
                    maxLength={256}
                />

                <LabeledInput
                    name="passwordMatch"
                    label="Confirm Password"
                    placeholder="password 8+ characters"
                    type="password"
                    autoComplete="password"
                    onFocus={() => setIsPasswordMatchActive(true)}
                    onBlur={validatePasswordMatch}
                    error={
                        hasPasswordMatchError ? "Passwords must match" : null
                    }
                    icon={showErrorSuccessIcon(
                        inputs.passwordMatch as string,
                        isPasswordMatchActive,
                        inputs.passwordMatch !== inputs.password
                    )}
                    maxLength={256}
                />

                <label>
                    <input
                        name="terms"
                        type="checkbox"
                        defaultChecked={false}
                    />
                    I agree to iVerify&nbsp;
                    <Link
                        to={{ pathname: AppRoutes.termsPath }}
                        className="account-terms"
                        event={Events.terms}
                        parameters={{ from: "create_account" }}
                    >
                        Terms
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                        to={{ pathname: AppRoutes.privacyPath }}
                        className="account-terms"
                        event={Events.privacy}
                        parameters={{ from: "create_account" }}
                    >
                        Privacy Policy
                    </Link>
                </label>

                <Button
                    className="width-small mts"
                    disabled={!valid || loading}
                    label={loading ? "Creating Organization..." : "Sign Up"}
                />
            </form>
        </>
    );
};

export default CreateAccountForm;
